$primary-light-color: #00d8ff;
$black: #14111F;
$primary-dark-color: #14111F;
$primary-dark-color-2: #211b35;
$white: #ffffff;
$white-shade-2: #eeecf3;
$white-shade-3: #D6DBDF;
$white-opacity-shade: rgba(255, 255, 255, 0.3);
$white-opacity-shade-6: rgba(255, 255, 255, 0.6);
$card-background: #1D192E;
$border-color: rgba(142, 140, 146, 0.5);
$border-color-54: #4c4a54;
$full-black: #000000;
$black-shade-1: rgb(20, 17, 31, 0.8);
$black-shade-2: #14111F;
$black-shade-3: #23262d;
$grey-shade-1: #484848;
$grey-shade-2: #979797;
$grey-shade-3: #8e8c92;
$grey-shade-4: #443f4f;
$grey-shade-5: #b0aeb7;
$grey-shade-6: #565656;
$grey-shade-7: #bfbbd0;
$grey-shade-8: #808080;
$bg-green: #008000;
$red: #FF756B;