@import './variables';

.tab-container {
    margin: 24px auto 24px 0;
    box-sizing: border-box;

    .mat-button-toggle-group {
        border: 1px solid $white-opacity-shade-6;
        box-shadow: 0px 0px 4px $primary-light-color;
        border-radius: 24px;
        max-width: 320px;
        background-color: $black-shade-2;
        width: 100%;
        height: 50px;

        .mat-button-toggle {
            height: 100%;
            background-color: $black-shade-2;
            color: $white-opacity-shade-6;
            width: 100%;
            border-radius: 24px;
            border: 8px solid $black-shade-2 !important;
            display: flex;
            align-items: center;

            // &::ng-deep {
            .mat-button-toggle-button {
                .mat-button-toggle-label-content {
                    line-height: 25px !important;
                }
            }

            // }

            &.mat-button-toggle-checked {
                background-color: $primary-light-color;
                color: $full-black;
                font-weight: 700;

            }
        }
    }

    @media screen and (max-width:768px) {
        .mat-button-toggle-group {
            max-width: 100% !important;
        }
    }
}