// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
@import './theme.scss';
@import './assets/styles/index.scss';
@import './assets/styles/variables';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// NextGem Colors: #141120, #00D8FF
$nextgem-webview-primary: mat-palette($md-primary);
$nextgem-webview-accent: mat-palette($md-accent);

// The warn palette is optional (defaults to red).
$nextgem-webview-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$nextgem-webview-theme: mat-dark-theme(( // setting dark as default so items show better on the page
        color: (primary: $nextgem-webview-primary,
            accent: $nextgem-webview-accent,
            warn: $nextgem-webview-warn,
        )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($nextgem-webview-theme);

// Light mode theme
$nextgem-light-theme: mat-light-theme(( // light theme shows up on light backgrounds, like the filter modal
        color: (primary: $nextgem-webview-primary,
            accent: $nextgem-webview-accent,
            warn: $nextgem-webview-warn,
        )));

@include angular-material-theme($nextgem-light-theme);

// $primary: mat-color($nextgem-webview-primary);
// $accent: mat-color($nextgem-webview-accent);
.ng-light-theme {
    @include mat-core($nextgem-light-theme);
}

/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";

@font-face {
    src: url(/assets/fonts/DinPro.ttf);
    font-family: DinPro;
}

html,
body {
    height: 100%;
}

body {
    background: rgb(20, 17, 31);
    color: #ffffff;
    font-family: DinPro;
}

/* Scroll CSS */
::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: #42404a;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #b30000;
}

.clickable {
    cursor: pointer;
}

.card-active {
    border: 4px solid #00d8ff;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.dialog-bg-trans {
    background-color: #14111F;
    opacity: 0.6 !important;
}

.mat-paginator {
    background-color: #14111F;
}

/* Change the color of the arrows */
.mat-paginator .mat-paginator-range-label {
    color: #ffffff;
}

.mat-paginator .mat-paginator-range-actions {
    button {
        color: #00d8ff;

        &:disabled {
            color: #ffffff;
        }
    }
}

.mat-paginator .mat-paginator-page-size-label {
    color: #ffffff;
}

.mat-form-field {
    background-color: #14111F;
    color: #fff;
}

.mat-input-element {
    caret-color: #fff;
}

input.mat-input-element {
    color: #fff;
}

//input outline color
.mat-form-field-appearance-outline .mat-form-field-outline,
label {
    color: #fff;
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-form-field-label {
    color: #fff;
}

.mat-form-field-appearance-fill {
    background-color: transparent;
}

.mat-form-field-appearance-fill.mat-focused .mat-form-field-ripple {
    background-color: #00d8ff;
}

.mat-select-value-text {
    color: #fff;
}

.my-custom-select .mat-form-field-underline {
    background-color: #00d8ff;
}

.mat-select-arrow {
    color: #00d8ff !important;
}

.mat-input-element::placeholder {

    color: #fff;
}

/* Override the panel background color */
.mat-select-panel {
    background-color: #14111F;
    border: 1px solid #00d8ff;
    border-radius: 4px;
}

/* Style the label color */
.mat-option .mat-option-text,
.mat-hint {
    color: #ffffff !important;
}

/* Style the selected label color */
.mat-option.mat-selected .mat-option-text {
    color: #00d8ff;
    /* Replace 'red' with the desired color */
}

/* Style the checkbox color - unchecked state */
.mat-option:not(.mat-selected) .mat-pseudo-checkbox {
    background-color: #ffffff;
    border-color: #ffffff;
}