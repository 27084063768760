/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #e3e2e4,
    100 : #b9b8bc,
    200 : #8a8890,
    300 : #5b5863,
    400 : #373541,
    500 : #141120,
    600 : #120f1c,
    700 : #0e0c18,
    800 : #0b0a13,
    900 : #06050b,
    A100 : #5151ff,
    A200 : #1e1eff,
    A400 : #0000ea,
    A700 : #0000d0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-accent: (
    50 : #e0faff,
    100 : #b3f3ff,
    200 : #80ecff,
    300 : #4de4ff,
    400 : #26deff,
    500 : #00d8ff,
    600 : #00d4ff,
    700 : #00ceff,
    800 : #00c8ff,
    900 : #00bfff,
    A100 : #ffffff,
    A200 : #f2fbff,
    A400 : #bfedff,
    A700 : #a6e5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// $md-nextgem-theme: (
//     50 : #e0faff,
//     100 : #b3f3ff,
//     200 : #80ecff,
//     300 : #4de4ff,
//     400 : #26deff,
//     500 : #00d8ff,
//     600 : #00d4ff,
//     700 : #00ceff,
//     800 : #00c8ff,
//     900 : #00bfff,
//     A100 : #ffffff,
//     A200 : #f2fbff,
//     A400 : #bfedff,
//     A700 : #a6e5ff,
//     contrast: (
//         50 : #000000,
//         100 : #000000,
//         200 : #000000,
//         300 : #000000,
//         400 : #000000,
//         500 : #000000,
//         600 : #000000,
//         700 : #000000,
//         800 : #000000,
//         900 : #000000,
//         A100 : #000000,
//         A200 : #000000,
//         A400 : #000000,
//         A700 : #000000,
//     )
// );

